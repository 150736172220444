<template>
    <div class="dhvBottom">
        <div class="dhbLeft">
            <p class="dblP">一个域名，成就一个梦想</p>
            <div class="dblBtn">
                立即咨询
                <div class="dbCode">
                    <img src="http://iv.okvu.cn/vugw/img/wygs.png" alt="" />
                </div>
            </div>
        </div>
        <video class="dhbVideo" src="http://iv.vu818.com/video/blue_bg.mp4" autoplay loop muted></video>
    </div>
</template>

<script>
export default {
    name: "DhvBottom",
};
</script>

<style lang="less" scoped>
.dhvBottom {
    max-width: 1920px;
    height: 335px;
    margin: 0 auto;
    margin-top: 50px;
    position: relative;
    overflow: hidden;
    .dhbLeft {
        width: 308px;
        height: 120px;
        position: absolute;
        top: 35%;
        left: 10%;
        z-index: 99;
        .dblP {
            width: 100%;
            height: 40px;
            line-height: 40px;
            font-size: 28px;
            color: white;
        }
        .dblBtn {
            width: 136px;
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            background-color: white;
            border-radius: 5px;
            margin-top: 40px;
            text-align: center;
            color: #2468f2;
            cursor: pointer;
            position: relative;
            .dbCode {
                width: 100px;
                height: 100px;
                border-radius: 20px;
                position: absolute;
                right: -120px;
                top: -20px;
                opacity: 0;
                transition: 0.5s;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 20px;
                }
            }
        }
        .dblBtn:hover {
            .dbCode {
                opacity: 1;
            }
        }
    }
    .dhbVideo {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}
</style>
