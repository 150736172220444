<template>
    <!-- 域名 -->
    <div class="domain">
        <!-- 头部搜索 -->
        <DhTop></DhTop>
        <!-- 热门推荐、域名交易 -->
        <DhHotRec></DhHotRec>
        <!-- 视频 -->
        <DhVideo></DhVideo>
        <!-- 三个模块 -->
        <DhThree></DhThree>
        <!-- 底部视频 -->
        <DhvBottom></DhvBottom>
    </div>
</template>

<script>
import DhTop from "../components/content/Domains/DmHome/DhTop.vue";
import DhHotRec from "../components/content/Domains/DmHome/DhHotRec.vue";
import DhVideo from "../components/content/Domains/DmHome/DhVideo.vue";
import DhThree from "../components/content/Domains/DmHome/DhThree.vue";
import DhvBottom from "../components/content/Domains/DmHome/DhvBottom.vue";

export default {
    name: "Domain",
    components: {
        DhTop,
        DhHotRec,
        DhVideo,
        DhThree,
        DhvBottom,
    },
};
</script>

<style lang="less" scoped>
.domain {
    margin: 0 auto;
}
</style>
