<template>
    <div class="dhTop">
        <!-- 背景 -->
        <div class="dtBg">
            <img src="http://iv.vu818.com/video/mebg.png" alt="" class="dtbImg" />
        </div>
        <div class="dtBox">
            <!-- 标题 -->
            <div class="dbTop1">
                <p class="dtBig">威有智能云·域名服务</p>
                <p class="dtSmall">百余款域名后缀随心选，注册任意域名即赠免费威有官方建站应用和CDN新用户500G大礼包！</p>
            </div>
            <!-- 搜索 -->
            <div class="dbTop2">
                <div class="dtSearch">
                    <div class="dsBox1"><input type="text" /></div>
                    <button class="dsBox2">威有一下</button>
                </div>
                <!-- <div class="dtBtext">
          <div class="dbtLeft">
            <p class="dtlP">.com 威有25（新客专享）</p>
            <p class="dtlP">.cn 威有15起</p>
            <div class="dtlP">
              <a
                href="vu818.com/product/bcd/price.html?track=BCD_PORTAL&clientFrom=CLOUD"
                >总价总览</a
              ><i class="iconfont icon-youbianjiantou"></i>
            </div>
          </div>
          <div class="dbtRight">
            <ul>
              <li class="dtrLi">批量注册</li>
              <li class="dtrLi">委托想要</li>
              <li class="dtrLi">域名交易</li>
            </ul>
          </div>
        </div> -->
            </div>
            <!-- 底部盒子 -->
            <div class="dbTop3">
                <img src="http://iv.okvu.cn/vugw/img/nav-bg.png" alt="" class="dt3Bg" />
                <ul class="dt3Ul">
                    <li class="dt3Li" v-for="item in 6" :key="item">
                        <div class="d3lBox">
                            <div class="d3b1">
                                <img src="http://iv.vu818.com/video/weituo.svg" alt="" class="d3Img" />
                                <p class="d3P">域名委托想要</p>
                            </div>
                            <div class="d3b2">域名回购，资深专家一对一服务</div>
                        </div>
                    </li>
                </ul>
                <div class="dt3Right">
                    <div class="d3rBox" v-for="item in 2" :key="item">
                        <p class="d3b1">域名管理</p>
                        <ul class="d3bUl">
                            <li class="d3bLi">
                                <a
                                    href="vu818.com/?redirect=https%3A%2F%2Fconsole.bce.baidu.com%2Fbcd%2F%3Ftrack%3DBCD_PORTAL%26clientFrom%3DCLOUD#/bcd/transferin/list"
                                    class="d3A"
                                    >域名转入</a
                                >
                            </li>
                            <li class="d3bLi">
                                <a
                                    href="vu818.com/?redirect=https%3A%2F%2Fconsole.bce.baidu.com%2Fbcd%2F%3Ftrack%3DBCD_PORTAL%26clientFrom%3DCLOUD#/bcd/transferin/list"
                                    class="d3A"
                                    >域名续费</a
                                >
                            </li>
                            <li class="d3bLi">
                                <a
                                    href="vu818.com/?redirect=https%3A%2F%2Fconsole.bce.baidu.com%2Fbcd%2F%3Ftrack%3DBCD_PORTAL%26clientFrom%3DCLOUD#/bcd/transferin/list"
                                    class="d3A"
                                    >云解析DNS</a
                                >
                            </li>
                            <li class="d3bLi">
                                <a
                                    href="vu818.com/?redirect=https%3A%2F%2Fconsole.bce.baidu.com%2Fbcd%2F%3Ftrack%3DBCD_PORTAL%26clientFrom%3DCLOUD#/bcd/transferin/list"
                                    class="d3A"
                                    >模板认证</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DhTop",
};
</script>

<style lang="less" scoped>
.dhTop {
    width: 100%;
    height: 550px;
    margin-top: 87px;
    overflow: hidden;
    // 背景
    .dtBg {
        width: 100%;
        height: 400px;
        position: absolute;
        z-index: -1;
        .dtbImg {
            width: 100%;
            height: 100%;
        }
    }
    .dtBox {
        width: 1210px;
        height: 400px;
        margin: 0 auto;
        margin-top: 50px;
        // 标题
        .dbTop1 {
            width: 50%;
            height: 80px;
            margin: 0 auto;
            .dtBig {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 36px;
                text-align: center;
            }
            .dtSmall {
                width: 100%;
                height: 30px;
                line-height: 30px;
                font-size: 14px;
                text-align: center;
                color: gray;
            }
        }
        // 搜索
        .dbTop2 {
            width: 80%;
            height: 100px;
            margin: 0 auto;
            margin-top: 20px;
            .dtSearch {
                width: 100%;
                height: 50px;
                background-color: #fff;
                border-radius: 10px;
                .dsBox1 {
                    width: 85%;
                    height: 100%;
                    float: left;
                    input {
                        width: 95%;
                        height: 48px;
                        border: none;
                        outline: none;
                        font-size: 15px;
                        padding-left: 20px;
                        border-top-left-radius: 10px;
                        border-bottom-left-radius: 10px;
                    }
                }
                .dsBox2 {
                    width: 15%;
                    height: 50px;
                    float: left;
                    border: none;
                    color: white;
                    font-size: 18px;
                    cursor: pointer;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    background: linear-gradient(to right, #7781f1, #7781f1e0);
                    outline: none;
                }
                .dsBox2:hover {
                    background: linear-gradient(to right, #e1aef8, #a977f5);
                }
            }
            // .dtBtext {
            //   width: 100%;
            //   height: 25px;
            //   line-height: 25px;
            //   margin-top: 15px;
            //   font-size: 13px;
            //   .dbtLeft {
            //     float: left;
            //     .dtlP {
            //       width: 150px;
            //       height: 25px;
            //       float: left;
            //       cursor: pointer;
            //     }
            //     .dtlP:hover {
            //       color: #7781f1;
            //       a,
            //       .iconfont {
            //         color: #7781f1;
            //       }
            //     }
            //     .dtlP:nth-child(1) {
            //       border-right: 1px solid gray;
            //     }
            //     .dtlP:nth-child(2) {
            //       width: 70px;
            //       margin-left: 10px;
            //     }
            //     .dtlP:nth-child(3) {
            //       width: 80px;
            //       height: 25px;
            //       margin-left: 20px;
            //       color: gray;
            //       .icon-youbianjiantou {
            //         margin-left: 5px;
            //       }
            //     }
            //   }
            //   .dbtRight {
            //     width: 200px;
            //     height: 25px;
            //     float: right;
            //     ul {
            //       width: 100%;
            //       height: 100%;
            //       display: flex;
            //       justify-content: space-around;
            //       .dtrLi {
            //         float: left;
            //         color: gray;
            //         cursor: pointer;
            //       }
            //       .dtrLi:hover {
            //         color: #7781f1;
            //       }
            //     }
            //   }
            // }
        }
        // 底部盒子
        .dbTop3 {
            width: 100%;
            height: 200px;
            margin-top: 50px;
            border-radius: 10px;
            box-shadow: 1px 1px 10px 1px gray;
            position: relative;
            .dt3Bg {
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: -1;
            }
            .dt3Ul {
                width: 60%;
                height: 200px;
                float: left;
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                .dt3Li {
                    width: 33%;
                    height: 50%;
                    cursor: pointer;
                    border-right: 1px solid rgba(34, 34, 34, 0.08);
                    .d3lBox {
                        width: 85%;
                        height: 50%;
                        margin: 0 auto;
                        margin-top: 10%;
                        .d3b1 {
                            width: 100%;
                            height: 26px;
                            .d3Img {
                                width: 22px;
                                height: 22px;
                                float: left;
                            }
                            .d3P {
                                font-size: 16px;
                                float: left;
                                margin-left: 10px;
                            }
                        }
                        .d3b2 {
                            font-size: 13px;
                            color: gray;
                        }
                    }
                }
                .dt3Li:nth-child(1),
                .dt3Li:nth-child(2),
                .dt3Li:nth-child(3) {
                    border-bottom: 1px solid rgba(34, 34, 34, 0.08);
                }
                .dt3Li:hover {
                    transition: 0.5s;
                    .d3P {
                        color: #7781f1;
                    }
                }
            }
            .dt3Right {
                width: 40%;
                height: 200px;
                float: left;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                .d3rBox {
                    width: 100%;
                    height: 50%;
                    border-top-right-radius: 10px;
                    .d3b1 {
                        width: 90%;
                        height: 30px;
                        line-height: 30px;
                        font-size: 16px;
                        margin: 0 auto;
                        padding-top: 20px;
                    }
                    .d3bUl {
                        width: 90%;
                        height: 25px;
                        margin-top: 10px;
                        line-height: 25px;
                        display: flex;
                        justify-content: space-around;
                        .d3bLi {
                            width: 100px;
                            text-align: center;
                            border-right: 1px solid gray;
                            .d3A {
                                font-size: 13px;
                            }
                        }
                        .d3bLi:hover {
                            .d3A {
                                color: #7781f1;
                            }
                        }
                    }
                }
                .d3rBox:nth-child(1) {
                    border-bottom: 1px solid rgba(34, 34, 34, 0.08);
                }
            }
        }
    }
}
</style>
