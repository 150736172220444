<template>
    <div class="dhVideo">
        <div class="dvBox">
            <div class="dvbTop">
                <p class="dbt1">为什么选择我们？</p>
                <p class="dbt2">为买卖双方提供丰富、安全、高效、便携的域名交易服务</p>
            </div>
            <div class="dvbBox">
                <div class="dbLeft">
                    <div class="dlText">
                        <p class="dlt1">品牌信誉</p>
                        <p class="dlt2">域名注册服务商</p>
                        <p class="dlt2">为千万级用户提供服务</p>
                        <div class="dltx"></div>
                    </div>
                    <div class="dlText">
                        <p class="dlt1">服务保障</p>
                        <p class="dlt2">提供国内外百余种域名后缀</p>
                        <p class="dlt2">管理功能丰富安全，为客户保驾护航</p>
                        <div class="dltx"></div>
                    </div>
                </div>
                <div class="dbCenter">
                    <video class="dcVideo" autoplay loop muted src="http://iv.vu818.com/video/server-func.mp4"></video>
                </div>
                <div class="dbRight">
                    <div class="drText">
                        <div class="dltx"></div>
                        <p class="drt1">DNS云解析</p>
                        <p class="drt2">专业DNS智能云解析</p>
                        <p class="drt2">免费6G解析高防，精准可靠</p>
                    </div>
                    <div class="drText">
                        <div class="dltx"></div>
                        <p class="drt1">1V1大客户支持</p>
                        <p class="drt2">一对一大客户服务支持对接</p>
                        <p class="drt2">提供批量注册，高效能对接</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DhVideo',
};
</script>

<style lang="less" scoped>
.dhVideo {
    width: 100%;
    height: 700px;
    margin: 0 auto;
    .dvBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        .dvbTop {
            width: 50%;
            height: 75px;
            margin: 0 auto;
            text-align: center;
            .dbt1 {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 28px;
            }
            .dbt2 {
                width: 100%;
                height: 25px;
                line-height: 25px;
                font-size: 14px;
                color: gray;
            }
        }
        .dvbBox {
            width: 100%;
            height: 550px;
            margin-top: 50px;
            position: relative;
            .dbLeft {
                width: 350px;
                height: 100%;
                float: left;
                .dlText {
                    width: 200px;
                    height: 85px;
                    position: relative;
                    z-index: 66;
                    .dlt1 {
                        width: 100%;
                        height: 25px;
                        line-height: 25px;
                        font-size: 18px;
                    }
                    .dlt2 {
                        width: 100%;
                        height: 24px;
                        line-height: 24px;
                        font-size: 14px;
                        color: gray;
                        padding-left: 15px;
                        position: relative;
                    }
                    .dlt2::before {
                        position: absolute;
                        top: 42%;
                        left: 2%;
                        content: '';
                        display: block;
                        width: 6px;
                        height: 6px;
                        background: #2468f2;
                        border-radius: 50%;
                    }
                    .dltx {
                        width: 200px;
                        height: 4px;
                        border-top: 1px dashed #888;
                        position: absolute;
                        top: 50%;
                        left: 90%;
                        z-index: 999;
                    }
                }
                .dlText:nth-child(1) {
                    margin-top: 30%;
                }
                .dlText:nth-child(2) {
                    margin-top: 60%;
                }
            }
            .dbCenter {
                width: 886px;
                height: 443px;
                position: absolute;
                top: 10%;
                left: 15%;
                .dcVideo {
                    width: 100%;
                    height: 100%;
                }
            }
            .dbRight {
                width: 350px;
                height: 100%;
                float: right;
                .drText {
                    width: 200px;
                    height: 85px;
                    margin-left: 40%;
                    position: relative;
                    z-index: 66;
                    .drt1 {
                        width: 100%;
                        height: 25px;
                        line-height: 25px;
                        font-size: 18px;
                    }
                    .drt2 {
                        width: 100%;
                        height: 24px;
                        line-height: 24px;
                        font-size: 14px;
                        color: gray;
                        padding-left: 15px;
                        position: relative;
                    }
                    .dltx {
                        width: 200px;
                        height: 4px;
                        border-top: 1px dashed #888;
                        position: absolute;
                        top: 50%;
                        right: 90%;
                        z-index: 999;
                    }
                    .drt2::before {
                        position: absolute;
                        top: 42%;
                        left: 2%;
                        content: '';
                        display: block;
                        width: 6px;
                        height: 6px;
                        background: #2468f2;
                        border-radius: 50%;
                    }
                }
                .drText:nth-child(1) {
                    margin-top: 30%;
                }
                .drText:nth-child(2) {
                    margin-top: 60%;
                }
            }
        }
    }
}
</style>
