<template>
    <div class="dhThree">
        <!-- 域名活动 -->
        <div class="dhtBox1">
            <div class="db1Top">域名活动</div>
            <div class="db1List">
                <ul>
                    <li class="db1Li">
                        <img src="http://iv.vu818.com/video/banner-1.png" alt="" class="d1lImg" />
                    </li>
                    <li class="db1Li">
                        <img src="http://iv.vu818.com/video/banner-2.png" alt="" class="d1lImg" />
                    </li>
                    <li class="db1Li">
                        <img src="http://iv.vu818.com/video/banner-3.png" alt="" class="d1lImg" />
                    </li>
                </ul>
            </div>
        </div>
        <!-- 使用指南 -->
        <div class="dhtBox2">
            <div class="db2Box">
                <div class="db2Top">使用指南</div>
                <div class="db2List">
                    <ul class="d2lUl">
                        <li class="d2lLi" v-for="item in 4" :key="item">
                            <div class="dlOne">
                                <img src="http://iv.vu818.com/video/huaban.svg" alt="" class="doImg" />
                                <p class="doP">想要域名</p>
                            </div>
                            <ul class="dlUl">
                                <li class="duLi">域名选购指南</li>
                                <li class="duLi">注册域名</li>
                                <li class="duLi">信息模板</li>
                                <li class="duLi">域名实名认证</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- 合作伙伴 -->
        <div class="dhtBox3">
            <div class="db3Box">
                <div class="db3Top">
                    <p class="d3t1">合作伙伴</p>
                    <p class="d3t2">威有智能云联合优秀合作伙伴，完善域名建设，共同加速域名发展</p>
                </div>
                <div class="db3List">
                    <ul>
                        <li class="d3lLi" v-for="item in 6" :key="item">
                            <img src="http://iv.vu818.com/video/xinwang.png" alt="" class="d3limg" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DhThree',
};
</script>

<style lang="less" scoped>
.dhThree {
    width: 100%;
    height: 1300px;
    // 域名活动
    .dhtBox1 {
        width: 100%;
        height: 420px;
        // background: url("vu818.com/bcd_portal/20220211/active-bg.png") no-repeat;
        color: white;
        .db1Top {
            width: 50%;
            height: 30px;
            margin: 0 auto;
            padding-top: 50px;
            line-height: 30px;
            font-size: 28px;
            text-align: center;
        }
        .db1List {
            width: 1280px;
            height: 230px;
            margin: 0 auto;
            margin-top: 40px;
            ul {
                width: 100%;
                height: 100%;
                justify-content: space-around;
                display: flex;
                .db1Li {
                    width: 380px;
                    height: 100%;
                    overflow: hidden;
                    border-radius: 10px;
                    .d1lImg {
                        width: 100%;
                        height: 100%;
                        transition: 0.5s;
                        border-radius: 10px;
                    }
                }
                .db1Li:hover {
                    .d1lImg {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
    // 使用指南
    .dhtBox2 {
        width: 100%;
        height: 540px;
        .db2Box {
            width: 1280px;
            height: 100%;
            margin: 0 auto;
            .db2Top {
                width: 50%;
                height: 30px;
                margin: 0 auto;
                padding-top: 30px;
                line-height: 30px;
                font-size: 28px;
                text-align: center;
            }
            .db2List {
                width: 100%;
                height: 440px;
                margin-top: 20px;
                .d2lUl {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    flex-direction: column;
                    .d2lLi {
                        width: 100%;
                        height: 72px;
                        box-shadow: 1px 1px 10px 1px gainsboro;
                        border-radius: 5px;
                        .dlOne {
                            width: 15%;
                            height: 100%;
                            float: left;
                            .doImg {
                                width: 26px;
                                height: 22px;
                                float: left;
                                padding-top: 12%;
                                margin-left: 5%;
                            }
                            .doP {
                                width: 100px;
                                height: 28px;
                                font-size: 22px;
                                line-height: 28px;
                                margin-left: 20px;
                                float: left;
                                text-align: center;
                                padding-top: 10%;
                            }
                        }
                        .dlUl {
                            width: 85%;
                            height: 100%;
                            line-height: 72px;
                            float: left;
                            display: flex;
                            .duLi {
                                width: 100px;
                                height: 100%;
                                margin-left: 20px;
                                font-size: 14px;
                                cursor: pointer;
                            }
                            .duLi:hover {
                                color: #7781f1;
                            }
                        }
                    }
                }
            }
        }
    }
    // 合作伙伴
    .dhtBox3 {
        width: 100%;
        height: 300px;
        .db3Box {
            width: 1280px;
            height: 100%;
            margin: 0 auto;
            .db3Top {
                width: 50%;
                height: 80px;
                margin: 0 auto;
                padding-top: 20px;
                text-align: center;
                .d3t1 {
                    width: 100%;
                    height: 50px;
                    line-height: 50px;
                    font-size: 28px;
                }
                .d3t2 {
                    width: 100%;
                    height: 30px;
                    line-height: 30px;
                    font-size: 14px;
                    color: gray;
                }
            }
            .db3List {
                width: 100%;
                height: 100px;
                margin-top: 50px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    .d3lLi {
                        width: 182px;
                        height: 100px;
                        border-radius: 10px;
                        box-shadow: 1px 1px 10px 1px gainsboro;
                        .d3limg {
                            width: 100%;
                            height: 100%;
                            border-radius: 10px;
                        }
                    }
                }
            }
        }
    }
}
</style>
